import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import PasteventCard from "./pasteventcard"

export default () => (
    <StaticQuery
        query={graphql` 
            query VodkapasteventsfeedQuery {
                allContentfulCard(
                    sort: {
                        fields: dateOrder, order: DESC
                    },
                    filter: {
                        spiritType: {title: {eq: "Vodka"}},
                        pastEvent: {eq: true}
                    } ) {
                    edges {
                        node {
                            title       
                            image {
                                title
                                fluid(maxWidth: 1200, quality: 85) {
                                    src
                                    ...GatsbyContentfulFluid
                                }
                            }
                            spiritType {
                                title
                            }
                            id
                            dateOrder  
                            eventsPage
                            pastEvent       
                        }
                    }
                }  
            }
        `}

      render={data => (
        <>
            {data.allContentfulCard.edges.map(edge => (

                <PasteventCard
                    key={edge.node.id}
                    image={edge.node.image.fluid.src}                        
                    title={edge.node.title}
                    alttext={edge.node.image.title}
                />
            
            ))}
        </>
      )}
    />
  )